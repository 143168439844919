import React, { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import "./RatingMatch.css";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFeedbacksData } from "../../data/feedbacks";

const RatingMatch = ({
  analysisid,
  preRating,
  preRemark,
  updateParentRating,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [hoverStates, setHoverStates] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
  });
  const { UpdateRating } = useFeedbacksData();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("820"));

  const handleChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    setErrorMessage("");
    setIsValid(newText.trim() !== "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (rating > 0) {
      UpdateRating({
        analysisid: analysisid,
        rating: rating,
        remark: text,
      })
        .then((response) => {
          updateParentRating({
            rating: rating,
            remark: text,
          });
          setIsOpen(false);
          return;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setErrorMessage("Please select the rating");
      return;
    }
  };

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleRating = (rating) => {
    setRating(rating);
  };

  useEffect(() => {
    setRating(preRating || 0);
  }, [preRating]);

  useEffect(() => {
    setText(preRemark || "");
  }, [preRemark]);

  const handleClosePopup = () => {
    setIsOpen(false);
  };
  const ratingOptions = [
    {
      value: 4,
      label: "GREAT!",
      activeImage: "/images/Emoji_1.gif",
      inactiveImage: "/images/great-star.svg",
    },
    {
      value: 3,
      label: "GOOD",
      activeImage: "/images/Emoji_4.gif",
      inactiveImage: "/images/good-star.svg",
    },
    {
      value: 2,
      label: "MEH!",
      activeImage: "/images/Emoji_2.gif",
      inactiveImage: "/images/meh-star.svg",
    },
    {
      value: 1,
      label: "NOT SATISFIED",
      activeImage: "/images/Emoji_3.gif",
      inactiveImage: "/images/not-statisfied-star.svg",
    },
  ];
  const handleHover = (value, isHovered) => {
    setHoverStates((prev) => ({ ...prev, [value]: isHovered }));
  };

  return (
    <>
      {isMobile ? (
        <div>
          <img
            onClick={handleDrawerToggle}
            className="w-5 h-5"
            style={{ color: "#22d6aa" }}
            src="/RateSmileyIcon.svg"
            alt="RateSmileyIcon"
          />
        </div>
      ) : (
        <div onClick={handleDrawerToggle} className="buttonboxsecond">
          <img className="iconboxsecond" src="/images/Rate.svg" alt="" />
          <div className="backbuttonsecond">
            <span
              className="ratethismatch"
              style={{ whiteSpace: "nowrap", width: "100%" }}
            >
              <span style={{ paddingRight: preRating ? "25px" : "0px" }}>
                {preRating ? "EDIT RATING" : "RATE THIS MATCH"}
              </span>
            </span>
          </div>
        </div>
      )}

      <Drawer anchor="bottom" open={isOpen} onClose={handleDrawerToggle}>
        {isOpen && (
          <>
            <div
              className="absolute top-0 left-0 w-full h-full bg-black opacity-30 cursor-pointer"
              onClick={handleDrawerToggle}
            ></div>
            <div
              className={`fixed mx-auto ${
                isMobile ? "w-full bg-white" : "w-[50%] bg-none"
              }  inset-x-0 bottom-0 z-50  transform transition-transform ease-in-out duration-300`}
            >
              {isMobile ? (
                ""
              ) : (
                <img
                  src="/Border.png"
                  alt=""
                  className="fixed h-[550px] w-full mx-auto"
                />
              )}

              <div
                style={{ scrollbarWidth: "none" }}
                className={`h-full overflow-y-auto ${
                  isMobile ? "" : "px-4 py-4"
                } ${isOpen ? "translate-y-0" : "translate-y-full"}`}
              >
                {/* Drawer content */}
                <div className="p-2 bg-none">
                  <div className="flex  items-end justify-end">
                    <img
                      onClick={handleDrawerToggle}
                      className="w-4 bg-transparent text-[#22D6AA] border-none cursor-pointer"
                      src="/clear.svg"
                      alt="Clear Dates"
                    />
                  </div>
                  <div className="flex justify-center">
                    <h1 className="text-xl font-bold  text-black">
                      Rate this Match
                    </h1>
                    {/* <div  style={{
                      position:"absolute",
                      marginTop:"0",
                      marginLeft:"600px",
                 
                    }}>
                      <img
                        src="/images/greenCross2.svg"
                        onClick={handleClosePopup}
                        style={{ cursor: "pointer" }}
                        alt="Close"
                      ></img>
                    </div> */}
                  </div>
                  <div
                    className={`p-4 ${
                      isMobile
                        ? "grid grid-cols-2"
                        : "flex items-center justify-center gap-14"
                    }`}
                  >
                    {ratingOptions.map(
                      ({ value, label, activeImage, inactiveImage }) => (
                        <div key={value} className="flex flex-col items-center">
                          <div
                            className="w-20 h-20 bg-cover duration-300 ease-in-out cursor-pointer"
                            style={{
                              backgroundImage: `url(${
                                rating === value || hoverStates[value]
                                  ? activeImage
                                  : inactiveImage
                              })`,
                            }}
                            onMouseEnter={() => handleHover(value, true)}
                            onMouseLeave={() => handleHover(value, false)}
                            onClick={() => handleRating(value)}
                          ></div>
                          <span
                            className={`text-base font-medium cursor-pointer ${
                              rating === value
                                ? "text-[#5A93ED]"
                                : "text-[#1F2E39]"
                            }`}
                            onMouseEnter={() => handleHover(value, true)}
                            onMouseLeave={() => handleHover(value, false)}
                            onClick={() => handleRating(value)}
                          >
                            {label}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                  <div className="flex items-center justify-center w-full">
                    <form onSubmit={handleSubmit}>
                      <div className="w-full">
                        <textarea
                          value={text}
                          onChange={handleChange}
                          placeholder="Type here to explain more or add your insight"
                          rows={6}
                          cols={60}
                          className={`max-h-44 overflow-y-scroll border border-solid text-[#1f2e39] text-[14px] ${
                            isMobile ? "w-[100%]" : ""
                          } rounded-md ${
                            isValid ? "border-[#708EA4]" : "!border-red-500"
                          } p-2 font-[Rubik]`}
                        />
                        {!isValid && (
                          <p className="text-red-500">{errorMessage}</p>
                        )}
                      </div>
                      <div className="flex justify-center mt-5 cursor-pointer">
                        <div
                          className="buttonbox"
                          onClick={(e) => handleSubmit(e)}
                        >
                          <p
                            type="submit"
                            className="text-[#1f2e39] text-base font-semibold "
                          >
                            SUBMIT
                          </p>
                          <img
                            className="iconbox"
                            src="/images/black-arrow.svg"
                            alt="arrow-icon"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default RatingMatch;
