import React from "react";

const Loading = () => {
  return (
    <img
      className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-30"
      src="/images/Loader.gif"
      alt="Loading"
    />
  );
};

export default Loading;
