import React from "react";

function HeaderBar({ title, toggleDrawer }) {
  const username = localStorage.getItem("username");

  return (
    <div className="bg-[url('/public/images/Green-Box.svg')] text-white flex justify-between items-center bg-no-repeat bg-cover rounded-md py-2 px-5 mx-2.5">
      <p className="text-lg font-bold">{title}</p>
      <div className="flex items-center gap-2">
        <img
          src="/images/notification-icon.svg"
          alt="Notification"
          className="h-6 transition-transform duration-500 ease-in-out hover:rotate-[25deg] cursor-pointer"
          // onClick={toggleDrawer}
        />
        <img src="/jobLogo.svg" alt="Job Logo" />
        {username && <p>{username}</p>}
      </div>
    </div>
  );
}

export default HeaderBar;
