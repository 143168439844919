import React from "react";

function MarkFavorite({ onClick, imgUrl }) {
  return (
    <img
      className="flex justify-center cursor-pointer"
      src={imgUrl}
      alt="MarkFavorite-icon"
      onClick={onClick}
    />
  );
}

export default MarkFavorite;
