import React from "react";
import { storage } from "../../libs/localStorage";

const StdResumeListPopup = ({ open, setOpen, templatedata, data }) => {
  const { retrieveToken } = storage();
  const token = retrieveToken();

  const handleClose = () => setOpen(false);

  const handleTemplateClick = (templateId) => {
    if (data?.standardizedcvjson) {
      const downloadUrl = `${process.env.REACT_APP_API_BASE_URL}/api/downloadstandardizecv?token=${token}&analysisid=${data?.analysisid}&templateid=${templateId}`;
      window.open(downloadUrl, "_blank");
    }
    handleClose();
  };

  if (!open) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={handleClose}
    >
      <div
        className="bg-white w-72 max-h-64 rounded-lg shadow-lg relative flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-2 right-2 text-green-500"
          onClick={handleClose}
        >
          <img src="/images/Path 1670.svg" alt="close" className="w-4 h-4" />
        </button>

        <div className="p-4 flex flex-col items-center">
          <h2 className="text-lg font-bold mb-5">Select Template</h2>

          {templatedata?.map(({ templateid, templatename }, index) => (
            <div
              key={templateid}
              className={`w-full py-2 px-4 cursor-pointer text-sm font-medium text-gray-800 ${
                index % 2 === 0 ? "bg-[#EDF4FF]" : "bg-[#ffffff]"
              }`}
              onClick={() => handleTemplateClick(templateid)}
            >
              {templatename}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StdResumeListPopup;
