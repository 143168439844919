import React, { useState, useEffect } from "react";

export default function VerifyPasswordPopup({
  handleSaveChanges,
  handleClosePopup,
  isPopupOpen,
}) {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    setPassword("");
    setErrorMessage("");
    setIsPasswordVisible(false);
  }, [isPopupOpen]);

  const validatePassword = (value) => {
    if (!value) return "Password is required.";
    return "";
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    const validationError = validatePassword(value);
    setErrorMessage(validationError);
  };

  const handleSave = () => {
    const validationError = validatePassword(password);
    if (validationError) {
      setErrorMessage(validationError);
    } else {
      setErrorMessage("");
      handleSaveChanges(password);
    }
  };
  if (!isPopupOpen) return null;

  const handleCancel = () => {
    handleClosePopup();
  };

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50"
      onClick={handleClosePopup}
    >
      <div
        className="bg-white p-6 rounded-md shadow-lg max-w-sm w-80 flex flex-col gap-2"
        onClick={(e) => e.stopPropagation()} // Prevent dialog from closing when clicked inside
      >
        <div className="flex justify-between">
          <p className="text-left font-bold text-lg">
            Enter password to continue
          </p>
          <img
            onClick={handleCancel}
            className="w-3 h-3 cursor-pointer"
            src="/images/Path 1670.svg"
            alt="close"
          />
        </div>
        <div className="relative">
          <input
            type={isPasswordVisible ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            className="block w-full h-10 border border-gray-400 rounded-md bg-white px-2 text-gray-700 pr-10"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute top-2 right-3"
          >
            <img
              src={
                isPasswordVisible
                  ? "/images/open-eye.svg"
                  : "/images/close-eye.svg"
              }
              alt="toggle visibility"
              className="w-5 h-5"
            />
          </button>
        </div>

        {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}

        <button
          type="button"
          className="w-20 py-2 bg-primary rounded-md text-white font-medium"
          onClick={handleSave}
        >
          Verify
        </button>
      </div>
    </div>
  );
}
